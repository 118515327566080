@use "utility" as *;
@use "sass:color";

// Color variants are calculated with oklch
// Percentages are based on the oklch lightness
// oklch is based on perceived lightness and
// results in a consistent color palette experience
// -
// https://oklch.com/

// Contrast can be checked for accessibility
// -
// https://webaim.org/resources/contrastchecker/

// Brands
$linkedin: #0a66c0;
$linkedin-hover: #005db6;
$werfy: #ef2767;

/* Black & White - OKLCH Lightness% */
$black: #010202; // 8%
$gray-11: #0c0d0d; // 16%
$gray-10: #1e1f1f; // 24%
$gray-9: #323333; // 32%
$gray-8: #474848; // 40%
$gray-7: #5c5e5e; // 48%
$gray-6: #737575; // 56%
$gray-5: #8b8c8c; // 64%
$gray-4: #a3a5a5; // 72%
$gray-3: #bcbebe; // 80%
$gray-2: #d6d8d8; // 88%
$gray-1: #f0f2f2; // 96%
$gray-0: #f7f9f9; // 96%
$white: #fff; // 100%

// Background
$background-light: $gray-2;
$background-lighter: $gray-1;
$background-white: $white;

// Border
$border-dark: $gray-4;
$border: $gray-3;
$border-light: $gray-2;

// Text
$text-dark: $gray-11;
$text: $gray-10;
$text-light: $gray-9;

/*
 * Herein lies your power, wizard of the machines
 * Tweak these variables to bend styles to your will
 */

$source-base: $linkedin;
$source-accent: $werfy;
$source-success: green;
$source-info: #00afbf;
$source-warning: #fda172;
$source-error: #f03;
$source-special: #8243f4;
$source-neutral: #1f1f1f;

/*
 * End of the enchanted realms - venture below at your own peril
*/

// Utility Colors

// Base
$base-light: ok-light($source-base);
$base: ok-fill($source-base);
$base-hover: ok-hover($source-base);
$base-dark: ok-dark($source-base);
$base-background: ok-background($source-base);

// Accent
$accent-light: ok-light($source-accent);
$accent: ok-fill($source-accent);
$accent-hover: ok-hover($source-accent);
$accent-dark: ok-dark($source-accent);
$accent-background: ok-background($source-accent);

// Success
$success-light: ok-light($source-success);
$success: ok-fill($source-success);
$success-hover: ok-hover($source-success);
$success-dark: ok-dark($source-success);
$success-background: ok-background($source-success);

// Info
$info-light: ok-light($source-info);
$info: ok-fill($source-info);
$info-hover: ok-hover($source-info);
$info-dark: ok-dark($source-info);
$info-background: ok-background($source-info);

// Warning
$warning-light: ok-light($source-warning);
$warning: ok($source-warning, 69%, 0.18); // Because: orange
$warning-hover: ok-hover($source-warning);
$warning-dark: ok-dark($source-warning);
$warning-background: ok-background($source-warning);

// Error
$error-light: ok-light($source-error);
$error: ok-fill($source-error);
$error-hover: ok-hover($source-error);
$error-dark: ok-dark($source-error);
$error-background: ok-background($source-error);

// Special
$special-light: ok-light($source-special);
$special: ok-fill($source-special);
$special-hover: ok-hover($source-special);
$special-dark: ok-dark($source-special);
$special-background: ok-background($source-special);

// Success
$neutral-light: ok-light($source-neutral);
$neutral: ok-fill($source-neutral);
$neutral-hover: ok-hover($source-neutral);
$neutral-dark: ok-dark($source-neutral);
$neutral-background: ok-background($source-neutral);

// Shadows
$shadow-light: rgba($black, 0.05);
$shadow: rgba($black, 0.1);
$shadow-dark: rgba($black, 0.2);
$shadow-darker: rgba($black, 0.3);

// Scrollbar
$scroll-background: transparent;
$scroll-foreground: rgba($black, 0.25);
