$brand-font: "Pacifico", serif;
$title-font: "Exo 2", serif;
$text-font: "Open Sans", sans-serif;

/* Text Sizes */
$text-size-xs: 1.2rem;
$text-size-s: 1.4rem;
$text-size-m: 1.6rem;
$text-size-l: 1.8rem;

/* Text Line Heights */
// Line-heights for text are factor 1.7
$text-line-height-xs: 2rem;
$text-line-height-s: 2.4rem;
$text-line-height-m: 2.7rem;
$text-line-height-l: 3rem;

/* Title Sizes */
$title-size-s: 1.8rem;
$title-size-m: 2rem;
$title-size-l: 2.4rem;
$title-size-xl: 3.2rem;
$title-size-xxl: 4rem;

/* Title Line Heights */
// Line-heights for titles are factor 1.4
$title-line-height-s: 2.5rem;
$title-line-height-m: 2.8rem;
$title-line-height-l: 3.4rem;
$title-line-height-xl: 4.5rem;
$title-line-height-xxl: 5.6rem;
